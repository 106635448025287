import HomeHandler from '../../handlers/home.handler';
import { iconChangeUser, iconChevronDown } from '../templates/icons';

class Home extends HTMLElement {
  connectedCallback() {
    this.render();
    this.handler();
  }

  render() {
    this.innerHTML = `
      <div id="home-container" class="m-5 lg:w-2/5 lg:mx-auto">
        
        <!-- <button id="change-role-btn" class="flex gap-x-1 items-center mx-auto mb-5 pb-2 border-b border-[#3572EF]">
          ${iconChangeUser}
          <p class="text-lg text-[#3572EF] ml-1 mr-8">As <span id="home-user-role"></span></p>
        </button>        
        <p class="mb-5">Hai, <span id="username"><span></p> -->

        <button id="change-role-btn" class="flex gap-x-1 items-center mb-5 pb-2 border-[#3572EF]">
          ${iconChangeUser}
          <p class="ml-1">Hai, <span id="username"><span></p>
          <p>(<span id="home-user-role" class="text-[#3572EF]"></span>)</p>
        </button>

        <div id="container-action-payment"></div>
        <h1 class="text-2xl mb-5">Payments</h1>

        <!-- <div class="flex justify-between mb-5">
          <div class="w-3/5">
            <label for="month" class="block text-sm leading-6">Month</label>
            <div class="relative mt-2">
              <div class="absolute inset-y-0 right-0 flex items-center pr-3">${iconChevronDown}</div>
              <select id="month" name="month" class="appearance-none text-[#3572EF] bg-white block w-full rounded-md border-0 h-10 px-3 shadow-sm ring-1 ring-inset ring-[#3572EF] focus:ring-2 focus:outline-0">
                <option value="0" class="checked:bg-[#3572EF] checked:text-white">All</option>
                <option value="1" class="checked:bg-[#3572EF] checked:text-white">January</option>
                <option value="2" class="checked:bg-[#3572EF] checked:text-white">February</option>
                <option value="3" class="checked:bg-[#3572EF] checked:text-white">March</option>
                <option value="4" class="checked:bg-[#3572EF] checked:text-white">April</option>
                <option value="5" class="checked:bg-[#3572EF] checked:text-white">May</option>
                <option value="6" class="checked:bg-[#3572EF] checked:text-white">June</option>
                <option value="7" class="checked:bg-[#3572EF] checked:text-white">July</option>
                <option value="8" class="checked:bg-[#3572EF] checked:text-white">August</option>
                <option value="9" class="checked:bg-[#3572EF] checked:text-white">September</option>
                <option value="10" class="checked:bg-[#3572EF] checked:text-white">October</option>
                <option value="11" class="checked:bg-[#3572EF] checked:text-white">November</option>
                <option value="12" class="checked:bg-[#3572EF] checked:text-white">December</option>
              </select>
            </div>
          </div>
          <div class="w-2/6">
            <label for="year" class="block text-sm leading-6">Year</label>
            <div class="relative mt-2">
              <div class="absolute inset-y-0 right-0 flex items-center pr-3">${iconChevronDown}</div>
              <select id="year" name="year" class="appearance-none text-[#3572EF] bg-white block w-full rounded-md border-0 h-10 px-3 shadow-sm ring-1 ring-inset ring-[#3572EF] focus:ring-2 focus:outline-0">
                <option value="2024" class="checked:bg-[#3572EF] checked:text-white">2024</option>
              </select>
            </div>
          </div>
        </div> -->

        <div id="container-request-only"></div>
        <div id="container-payments" class="mb-5"></div>
        <div id="container-nav-page" class="flex justify-between"></div>
        <div id="container-load"></div>
      </div>
    `;
  }

  handler() {
    const homeContainer = this.querySelector('#home-container');
    const changeRoleBtn = this.querySelector('#change-role-btn');
    const homeUserRole = this.querySelector('#home-user-role');
    const username = this.querySelector('#username');
    const containerActionPayment = this.querySelector('#container-action-payment');
    const containerRequestOnly = this.querySelector('#container-request-only');
    // const month = this.querySelector('#month');
    // const year = this.querySelector('#year');
    const containerLoad = this.querySelector('#container-load');
    const requestOnlyComponent = `
      <div class="relative flex gap-x-3 mb-5">
        <div class="flex h-6 items-center">
          <input id="req-only" name="req-only" type="checkbox" class="h-4 w-4 rounded accent-[#3572EF] cursor-pointer">
        </div>
        <label for="req-only" class="text-[#3572EF]">Request only</label>
      </div>
    `;
    HomeHandler.init({
      homeContainer,
      changeRoleBtn,
      homeUserRole,
      username,
      containerActionPayment,
      containerRequestOnly,
      // month,
      // year,
      requestOnlyComponent,
      containerLoad,
    });
  }
}

customElements.define('home-page', Home);
const home = '<home-page></home-page>';
export default home;
