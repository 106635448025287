import LoginHandler from '../../handlers/login.handler';

class Login extends HTMLElement {
  connectedCallback() {
    this.render();
    this.handler();
  }

  render() {
    this.innerHTML = `
      <div id="login-container" class="m-5 lg:w-2/5 lg:mx-auto">
        <img src="./assets/images/logo.png" alt="logo" width="120px" height="120px" class="mx-auto mb-10">
        <h1 class="text-3xl text-center">SIMPAMAS</h1>
        <p class="mb-10 text-center">Sistem Informasi Pembayaran PAMAS</p>
        <div id="login-form-container" class="mb-24"></div>
        <button onclick="location.assign('#/reset-password')" class="text-[#050C9C] h-11 underline mx-auto block">Forgot your password?</button>
        <div id="container-load"></div>
      </div>
    `;
  }

  handler() {
    const loginContainer = this.querySelector('#login-container');
    const loginFormContainer = this.querySelector('#login-form-container');
    const containerLoad = this.querySelector('#container-load');
    LoginHandler.init({
      loginContainer,
      loginFormContainer,
      containerLoad,
    });
  }
}

customElements.define('login-page', Login);
const login = '<login-page></login-page>';
export default login;
